.mat-form-field-flex{    
  //background: #fff;
  border-radius: 7px !important;
}
.mat-option{
  font-size: 13px!important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
     
}

/* Structure */
table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #0d6efd;
}