.buscador{
    background: var(--white-color);
    position: relative;
    top: -128px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: -70px;
    padding: 70px;
    padding-left: 128px !important;
    padding-right: 128px !important;
   
}

.iconos {
  background: #D7D8D7;
  width: 100%;
  height: 111px !important;

  .etiqueta{
   
  }
    .etiqueta--color {
      background-color: #f2f2f2;
      border-radius: 10px;
      height: 60px;
      padding-left: 62px !important;
    }
    .etiqueta--icono{
      background: var(-var(--primary-color-light));
      height: 80px;
      width: 80px;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: -9px;
      text-align: center;
      color: white;
      font-size: 50px;
    }
    .etiqueta--texto{
      color: var(--danger-color);
    }
  
}

/* Location and details */

.mapa{
  height: 300px;
}

.titulo{
  text-align: center;
  font-weight: bold;
  color: var(--primary-color) !important; 
}
  .titulo2{
    font-weight:bold;
    color: var(--primary-color) !important; 
  }

.titulo--detalles{
  div{
    p{ 
      text-align: center;
      color:var(--gray-color-light);
    }
  }
}
  
/* Cards de te pueden interezar */

.titulo-empresa{
  text-align: center !important;
  font-weight: bold !important;
  color: var(--white-color) !important;
  background: var(--gray-color) !important;
  position: absolute !important;
  width: 70% !important;
  right: -35%;
  z-index: 2;
  height: 80px;
  padding-top: 18px;
  border-radius: 10px;
}

.compañia{
  font-weight:bold;
  color: var(--primary-color-dark) !important; 
  font-size: xx-large !important;
  padding-top: 22px !important;
}

.pieza{
  img{
    width: 136% !important;
  }

}

/* esto va en la partede varios  */

.color-fondo{
  background: var(--primary-color-light);
  color: white;
}
