.search {
    padding: 0px;
    width:100%; 
    height: 300px;    
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
    @media screen and (max-width: 768px) {            
        display: block;
        height: auto;
    }
    
    
    &__content{
        flex: 1;
        height:100%;
        padding: 20px;
        
        /* third search tab */
        &.green{
            background: var(--tab-third-color);
        }
        /* Fourth search tab */
        &.yellow{
            background: var(--tab-fourth-color);

            & button.btn-first {
                color: var(--red-color);
                outline: 1px solid var(--red-color);
                border-radius: 5px;
            }

            & button.btn-second {
                background-color: var(--red-color);
                color: var(--white-color);
                outline: none;
            }
        }
        /* Second search tab */
        &.blue{
            background: var(--tab-second-color);

            & button.btn.btn-primary {
                background-color: var(--red-color);
            }
        }
        /* First search tab */
        &.gray{
            background: var(--tab-first-color);
        }
        &.ng-star-inserted{        
            transition: all 4s ease;        
        }
        & h2{
            color: var(--white-color);
            text-align: right;
            @media screen and (max-width: 668px) {
                text-align: left;
            }
        }
        & h3{
            color: var(--white-color);
            margin: 0 110px 20px 40px;
            font-size: 24px;

            @media screen and (max-width: 668px) {
                margin: 20px 0;
            }
        }
        
        & .card {
            z-index: 3;
        }
        
        
        .btn.btn-sm {
            background-color: var(--red-color);
        }
    }
    &__title{
        writing-mode: vertical-rl;
        text-orientation: mixed;        
        height: 100%;
        width: 45px;
        padding: 10px;
        color: #fff;
        border-radius: 5px 0px 0px 5px;
        box-shadow: 0px 3px 6px #00000080;
        cursor: pointer;
        @media screen and (max-width: 768px) {            
            writing-mode: horizontal-tb;
            text-orientation: inherit ;        
            width: 100%;
            height: auto;
            background-color: var(--green-color-dark);            
        }
        &.green{
            // background: $secondary-color;  
            background-color: var(--tab-title-first-color);            
            z-index: 1;          
        }
        &.yellow{
            // background: $yellow-color; 
            background-color: var(--tab-title-second-color); 
            z-index: 0;           
        }
        &.blue{
            // background: $primary-color-dark; 
            background-color: var(--tab-title-third-color);            
            z-index: 2;           
        }
        &.gray{
            background-color: var(--tab-title-fourth-color);
        }
        &.active {
            background-color: var(--blue-color);
        }
        &.after-active {
            border-radius: 0px 5px 5px 0px;
            box-shadow: 0px 3px 6px #00000080;
            // background-color: var(--red-color);

            &::after{
                box-shadow: -1px -2px 2px #333;
            }
        }
        & span {
            @media screen and (min-width: 769px) {
                transform: rotate(180deg);
                display: inline-block;
            }
        }
    }
    
}

.suggest{   
    z-index: 3;         
    &__title{
        padding: 4px 5px;
        border: 0;
        text-align: left;
        background: var(--white-color);
        & .col-1{
            @media screen and (max-width: 768px) {            
                padding-left: 0;
            }
            
        }

        & .badge{
            background: var(--white-color);
            padding: 4px;                    
            padding: 2px 6px;
            border-radius: 23%;  
            color: var(--gray-color-light); 
            border: 0.5px solid var(--gray-color-light);
            
            & i{
                -webkit-transition: -webkit-transform 0.5s; /* Safari */
                transition: transform 0.5s;
                -webkit-transform: rotate(0deg); /* Safari */
                transform: rotate(0deg);
            }
        }
        
        &[aria-expanded="true"]{
            background: var(--gray-color-light);
            .badge{
                background: var(--success-color);
                padding: 4px;                    
                padding: 2px 6px;
                border-radius: 23%;  
                color: var(--white-color); 
                border: 0;
                
                & i{
                    -webkit-transition: -webkit-transform 0.5s; /* Safari */
                    transition: transform 0.5s;
                    -webkit-transform: rotate(180deg); /* Safari */
                    transform: rotate(180deg);             
         
                }
            }

        }
        
        
        
    }
    &__results{
        color: $gray-color;
        background: #F4F4F4;
        padding: 30px 20px;
        
        & a{
            text-decoration: underline !important;
        }
        
    }
    
}
.companySearch{
    background: #fff;
    border-radius:5px;
    padding-top: 8px;
    &--option{
        padding: 0 12px 0 12px;
        width: 25%;
        & .form-group {
            margin-bottom: 0rem;
        }
    }
}
    
    