.navbar{
    background: var(--white-color);
    padding: 0 1rem;
    box-shadow: 0px 3px 6px #00000029;
    flex-wrap: initial;
    min-height: 63px;
    & .navbar-nav{
        & .nav-link{
            padding: 0.3rem 0 0.3rem 2.5rem !important;
            color: var(--gray-color-dark-font);
            font-size: 12px;
            @media screen and (max-width: 420px) {
                padding: 0.9rem 0 0.3rem 0 !important;
              }
        }
    }   
    & .navbar-collapse{
        @media screen and (max-width: 420px) {
          margin-top:20px;
        }
        
    }
    
    
    &-logo{
        & img {
            height: 55px;
        }
    }

    .dropdown-language {
        .dropdown-menu {
            &.show {
                left: -80px;

                @media screen and (max-width: 668px) {
                    left: -20px;
                }
            }
        }
    }

    & .dropdown-language .dropdown-button{
        background-color: transparent;
        color: var(--blue-color);
    }

    .multitenant-switch {
        color: var(--blue-color);
        background-color: transparent;
    }
}
