$primary-color: #082652;
$primary-color-dark: #003b8c;
$primary-color-light: #5a91f0;

$secondary-color: #3CC003;
$secondary-color-dark: #008e00;
$secundary-color-light: #78f44a;

$yellow-color:#FFEE01;
$yellow-color-dark:#c7bc00;
$yellow-color-light:#ffff57;

$warning-color: #f8bc3b;
$danger-color: #ff5252;
$success-color: #00CB5C;

$gray-color:#2D3142;
$gray-color-dark:#04081c;
$gray-color-light:#686565;
$white-color:#FFFFFF;

/* Font Colors */


/* Rio-grande colors */ 
$green-color-dark: #2A8046;
$blue-color: #005BAA;
$tab-third-color: rgba(0,91,170,.8);
$tab-fourth-color: rgba(0,91,170,.8);
$tab-second-color: rgba(0,91,170,.8);
$tab-first-color: rgba(0,91,170,.8);
$red-color: #DB0016;
$gray-color-dark-font: #333333;
$transparency-banner: #FFFFFFCC;
$gray-color-footer: #B0B0B0;


