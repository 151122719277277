.btn{
    border-radius: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    &.btn-primary{
        background-color: var(--red-color);
        border: 1px solid var(--red-color);

        &:hover {
            color: var(--red-color);
            background-color: var(--white-color) !important;
        }
    }
    &.btn-success{
        border: 0px;
        background-color: var(success-color);
    }

    &.btn-secundary{
        border: 0px;
        color: var(--white-color);
        background-color: var(--secondary-color);
    }
    &.btn-white{
        border:1px solid 0px;
        color: var(--gray-color-light);
        background-color:var(--white-color)
    }
    &.btn-outline-primary {
        border-color: var(--red-color);
        background-color: var(--white-color);
        color: var(--red-color);

        &:hover {
            background-color: var(--red-color) !important;
            color: var(--white-color);
        }
    }

}
