$w-b-link:50%;
$w-b-btn:$w-b-link*2.8;

.banner{
        position: relative;
        top: -50px; 
        box-shadow: 0px 8px 11px #00000029;
    @media screen and (max-width: 420px) {        
        height: 100%;
        top: 0px; 
    }
    @media screen and (max-width: 768px) {
        background-image: none;  
        top: 0px; 
        padding-top: 15px;  
        margin-bottom: 30px;    
    }
    &-msg{
        height: 100%;
        @media screen and (max-width: 420px) {
             padding-top: 20px;  
             text-align: center;          
        }
        & h3{
            color: #555;
            font-size: 24px;
            @media screen and (max-width: 992px) {
                font-size: 1.5rem;
                text-align: center;
            }
        }
    }
    &-img {
        background-image: var(--banner-path);    
        background-repeat: no-repeat;  
        background-position: left center;
        background-size: cover;
    }
    &-link{
        background: var(--transparency-banner);
        height: 100%;
        width: $w-b-link;
        text-align: center;
        @media screen and (max-width: 420px) {
            width: 100%;
        }
        @media screen and (max-width: 992px) {
            width: 80%;
        }

        & p{
            color: var(--gray-color-dark-font);
            font-size: 1.1rem;
        }
        &__btn{
            background-color: var(--red-color) !important;
            width: 60%;
            border-radius: 5px !important; 
/*             position: relative;
            left: -20%; */
            // width: $w-b-btn;
            
            @media screen and (max-width: 768px) {
                left: 0;
                width: 100%;    
            }
            a{
                color:var(--white-color);
                &:hover{
                    text-decoration: underline;
                    color: var(--white-color);
                }
            }
        }
    }
    
}

/* Bannrt dos de paginas internas */

.bannerdos{
    background-image: url('/assets/images/Bg.png'); 
    display: block;
    width: 90% !important;
    height: 200px;
    margin: 0 auto;
    margin-top: 8rem;

    }



/* Banner paginas internas */

.banner-back{
    background-image: var(--banner-denuncia);   
    height: 150px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
   
} 

.cdk-global-overlay-wrapper {
    align-items: center !important;

    & .btn {
        &-default { 
            background: none;
            color: var(--red-color);
            border: 1px solid var(--red-color);
        }
        &-primary {
            color: var(--white-color);
            background-color: var(--red-color) !important;
        }
    }
}