@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');

body{
    font-family: 'Montserrat', sans-serif !important;
}



.fs-7 {font-size: 7px  !important;}
.fs-8 {font-size: 8px  !important;}
.fs-9 {font-size: 9px  !important;}
.fs-10{font-size: 10px !important;}
.fs-11{font-size: 11px !important;}
.fs-12{font-size: 12px !important;}
.fs-13{font-size: 13px !important;}
.fs-14{font-size: 14px !important;}
.fs-15{font-size: 15px !important;}
.fs-16{font-size: 16px !important;}
.fs-17{font-size: 17px !important;}
.fs-18{font-size: 18px !important;}
.fs-19{font-size: 19px !important;}
.fs-20{font-size: 20px !important;}
.fs-21{font-size: 21px !important;}
.fs-22{font-size: 22px !important;}




.text-primary{ color:var(--primary-color) !important; }
.text-alternative{ color:var(--secondary-color) !important; }
.text-danger { color:var(--danger-color)  !important; }
.text-success{ color:var(--success-color) !important; }
.text-blue{ color:var(--primary-color-dark) !important; }
