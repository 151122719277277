.footer {
    margin-top: 25px;
    width: 100%;
    height: 60px;
    
    &-top{
        text-align: center;
        background: var(--gray-color);
        font-size: 13px;
        padding: 15px 10px;
    }
    &-copy{
        background: var(--gray-color-footer);
        text-align: center;
        padding: 15px 0; 
        font-size: 14px;

    }
}