.link{
    
    & .row{
        @media screen and (max-width: 420px) {            
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

    }
    

    &-card{
        border-top: 3px solid var(--gray-color);
        box-shadow: 0px 3px 6px #00000029; 
        &__desc{
        
            padding: 35px 30px 0 0;
            text-align: right;
            @media screen and (max-width: 1200px) {            
                padding: 15px 30px 0 0;
            }
            @media screen and (max-width: 768px) {            
                text-align: center;
            }
            & h3{
                margin-bottom: 30px;
                font-size: 24px;
                @media screen and (max-width: 960px) {            
                    margin-bottom: 10px;
                    font-size: 22px;
                }
            }
            
            & p{
                margin-bottom: 30px;
                color: #838286;
                @media screen and (max-width: 768px) {            
                    padding: 0 30px;
                }
            }
            @media screen and (max-width: 420px) {            
                text-align: center;
                padding: 35px !important;
                background: #EBEBEB;
            }
            & a.btn {
                background-color: var(--red-color);
            }
        }

    }
    &-card{
        &__img{
        overflow: hidden;
            @media screen and (max-width: 768px) {            
                display: none;
            }
        }
    }
}