.sidebar{
    background: #EBEBEB;
    height: 100%;
    
}

.formulario {
    /* --------------------------------------- */
    /* ----- Radio Button */
    /* --------------------------------------- */
    /* --------------------------------------- */
    /* ----- Checkbox */
    /* --------------------------------------- */ }
    .formulario h2 {
      font-size: 16px;
      color: #001F3F;
      margin-bottom: 20px;
      margin-left: 20px; }
    .formulario > div {
      padding: 10px 0;}
    .formulario .radio label,
    .formulario .checkbox label {
      display: inline-block;
      cursor: pointer;
      color: var(--primary-color);
      position: relative;
      padding: 4px 14px 5px 51px;  
      font-size: 12px;
      border-radius: 5px;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .formulario .radio label:hover,
      .formulario .checkbox label:hover {
        background: rgba(255, 65, 54, 0.1); }
      .formulario .radio label:before,
      .formulario .checkbox label:before {
        content: "";
        display: inline-block;
        width: 17px;
        height: 17px;
        position: absolute;
        left: 15px;
        border-radius: 50%;
        background: none;
        border: 3px solid var(--primary-color); }
    .formulario input[type="radio"] {
      display: none; }
      .formulario input[type="radio"]:checked + label:before {
        display: none; }
      .formulario input[type="radio"]:checked + label {
        padding: 5px 15px;
        background: var(--primary-color);
        border-radius: 2px;
        color: #fff; }
    .formulario .checkbox label:before {
      border-radius: 3px; }
    .formulario .checkbox input[type="checkbox"] {
      display: none; }
      .formulario .checkbox input[type="checkbox"]:checked + label:before {
        display: none; }
      .formulario .checkbox input[type="checkbox"]:checked + label {
        background: var(--primary-color);
        color: #fff;
        padding: 5px 15px; }
  