/*
Template Name:Desmontes Brasil
Author: QUIPUX
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Footers
 *	4. Search
 *	5.
 *	6.
 *	7.
 *	8.
 */



@import "components/vars/vars";
@import "components/layout/layout";
@import "components/mixin/mixin";
@import "components/plugins/plugins";
@import "components/utiles/utiles"
